import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import Loader from './loader';
import { useNavigate } from 'react-router-dom';
import { useProductContext } from '../components/product-provider';
import Breadcrumb from "../components/breadcrumb"; 

const ProductList = () => {
    const { products, selectedCategory } = useProductContext();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [loading, setLoading] = useState(true);
    const modalRef = useRef(null);
    const navigate = useNavigate();

    // Filter products dynamically using useMemo
    const filteredData = useMemo(() => {
        setLoading(false);
        return products.filter((p) => p.category === selectedCategory);
    }, [products, selectedCategory]);

    // Modal open/close handlers
    const openModal = (item) => {
        setSelectedItem(item);
        setIsModalOpen(true);
    };

    const closeModal = useCallback(() => {
        setSelectedItem(null);
        setIsModalOpen(false);
    }, []);

    // Click outside to close modal
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                closeModal();
            }
        };

        if (isModalOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isModalOpen, closeModal]);

    if (loading) {
        return <Loader />;
    }

    return (
        <div id="main">
            <div className="inner">
                <Breadcrumb />
                <h3 style={{ color: "#c39228" }}>{filteredData[0].category} Products</h3>
                <section className="tiles">
                    {filteredData.map((item, index) => {
                        const styleClass = `style${(index % 6) + 1}`;

                        return (
                            <article className={styleClass} key={item.id}>
                                <span className="image" style={{ cursor: 'pointer' }}>
                                    <img src={item.image} alt={item.title} />
                                </span>
                                <a
                                    href={item.link}
                                    onClick={(e) => {
                                        e.preventDefault();
										if(item.category !== "Packing") {
											navigate('/ProductDetails', { state: { item } });
										} else {
											openModal(item);
										}
                                    }}
                                >
                                    <h2>{item.title}</h2>
                                </a>
                            </article>
                        );
                    })}

                    {isModalOpen && selectedItem && (
                        <div className="custom-modal">
                            <div className="modal-content" ref={modalRef}>
                                <span className="close-button" onClick={closeModal}>&times;</span>
                                <img src={selectedItem.image} alt={selectedItem.title} className="modal-image" />
                                <div className="text-container">
                                    <h2 className="modal-title">{selectedItem.title}</h2>
                                    {selectedItem.category === "Packing" ? (
                                        <div className="modal-description">
                                            {selectedItem.description.split("\n").map((line, index) => (
                                                <p key={index}>{line}</p>
                                            ))}
                                        </div>
                                    ) : (
                                        <p className="modal-description">{selectedItem.description}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </section>
            </div>
        </div>
    );
}

export default ProductList;
