import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Breadcrumb from "../components/breadcrumb";

const ProductDetails = () => {
  const [data, setData] = useState(null);  // Initialize as null
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const item = location.state?.item;
    if (item) {
      window.scrollTo(0, 0);
      setData(item);
    }
    setLoading(false);
  }, [location.state]);  // Ensure effect runs when location.state changes

  if (loading) return <p>Loading...</p>;

  return (
    <div id="main">
      <div className="inner">
        <Breadcrumb />
        {data ? (
          <>
            <span className="image right">
              <img src={data.image} alt={data.title || "Product"} />
            </span>
            <h3 style={{ color: "#c39228" }}>{data.title}</h3>
            {data.description
              ? data.description.split("\n").map((line, index) => (
                <p key={index}> ➤ 
                  {line.split(" ").map((word, i) =>
                    word.startsWith("https") ? (
                      <a key={i} href={word} target="_blank" rel="noopener noreferrer">
                        {word}
                      </a>
                    ) : (
                      word + " "
                    )
                  )}
                </p>
              ))
              : <p>No description available</p>}
            <br />
            {data.notes &&
              (<h3 style={{ color: "#c39228"  }}> 📋 Notes</h3>)
            }
            {data.notes &&
              data.notes.split("\n").map((line, index) => (
                <p key={index}> ✦ {line}</p>
              ))}
            <br />
            {data.details &&
              (<h3 style={{ color: "#c39228"  }}>Details</h3>)
            }
            {data.details &&
              data.details.split("\n").map((line, index) => (
                <p key={index}>{index + 1}. {line}</p>
              ))}

          </>
        ) : (
          <p>No product details available.</p>
        )}
      </div>
    </div>
  );
};

export default ProductDetails;
