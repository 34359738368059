import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useProductContext } from "../components/product-provider";

const Breadcrumb = () => {
    const { setSelectedCategory } = useProductContext();
    const location = useLocation();
    const navigate = useNavigate();
    const item = location.state?.item;
    const category = item?.category || "all";

    return (
        <nav aria-label="breadcrumb">
            <ul className="breadcrumb">
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>
                {location.pathname.includes("Products") ? (
                    <span>Products</span>
                ) : (
                    <Link to="/Products">Products</Link>
                )}
                </li>
                {(location.pathname.includes("ProductList") || location.pathname.includes("ProductDetails")) && (
                    <li>
                        {location.pathname.includes("ProductList") ? (
                            // Show plain text if already on ProductList
                            <span>Items</span>
                        ) : (
                            // Show link if on ProductDetails
                            <Link
                                to="/ProductList"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setSelectedCategory(category);
                                    navigate("/ProductList", { state: { item } });
                                }}
                            >
                                Items
                            </Link>
                        )}
                    </li>
                )}

                {location.pathname.includes("ProductDetails") && (
                    <li>Details</li>
                )}
            </ul>
        </nav>
    );
};

export default Breadcrumb;
