import React, { createContext, useState, useContext, useEffect } from "react";
import productData from '../util/products.json';

// Create a Context
const ProductContext = createContext();

// Create a Provider Component
export const ProductProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  // Load the product data on mount
  useEffect(() => {
    setProducts(productData);
  }, []);

  return (
    <ProductContext.Provider value={{ products, setProducts, selectedCategory, setSelectedCategory }}>
      {children}
    </ProductContext.Provider>
  );
};

// Custom hook to use ProductContext
export const useProductContext = () => {
  return useContext(ProductContext);
};
