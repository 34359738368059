import React, { useState, useRef, useEffect, useCallback } from 'react';
//import { getProducts } from '../services/api-services';
import Loader from './loader';
//import json from '../util/products.json';
import { useNavigate } from 'react-router-dom';
import { useProductContext } from '../components/product-provider';
import Breadcrumb from "../components/breadcrumb"; 

const Product = () => {
	const { products, setSelectedCategory } = useProductContext();
	const [data, setData] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [loading, setLoading] = useState(true);
	const modalRef = useRef(null);
	const navigate = useNavigate();

	const closeModal = useCallback(() => {
		setIsModalOpen(false);
	}, []);

	const handleClickOutside = useCallback((event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			closeModal();
		}
	}, [closeModal]);

	useEffect(() => {
		if(data.length) return;
		const fetchData = async () => {
		  //const products = await getProducts();
		  //const products = json;
		  setData(products);
		  setLoading(false);
		};
		fetchData();
	  });

	  // Group products by category
	  const categorizedData = data.reduce((acc, item) => {
        if (!acc[item.category]) {
            acc[item.category] = [];
        }
        acc[item.category].push(item);
        return acc;
    }, {});

	useEffect(() => {
		if (isModalOpen) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [isModalOpen, handleClickOutside]);

	if (loading) {
		return <Loader />;
	}

	return (
		<div id="main">
			<div className="inner">
			<Breadcrumb />
				<section className="tiles">
					{Object.entries(categorizedData).map(([category, products], index) => {
						const styleClass = `style${(index % 6) + 1}`;

						return (
							<article className={styleClass} key={index}>
								<span className="image" style={{ cursor: 'pointer' }}>
									<img src={products[0].image} alt={products[0].categoryTitle} />
								</span>
								<a href="/ProductList" onClick={(e) => {
									e.preventDefault();
									const item = products[0];
									setSelectedCategory(item.category);
									navigate('/ProductList', { state: { item } });
								 }}>
									<h2>{products[0].categoryTitle}</h2>
								</a>
							</article>
						);
					})}

				</section>
			</div>
		</div>
	);
}

export default Product;