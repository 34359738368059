// src/components/ContactUs.js
import React, { useState} from 'react';
import { sendEmail } from '../services/api-services';

const Footer = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    contactNo: '',
    message: '',
  });

  const [status, setStatus] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validate form data
    const formErrors = validateForm(formData);
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    if (isSubmitting) return;
  
    try {
      setIsSubmitting(true);
      const response = await sendEmail(formData);
      if (response.status === 201) {
        formErrors.email = 'Enter the valid email id!';
        setErrors(formErrors);
        setStatus('Failed to send message.');
      } else if (response.status === 200) {
        setErrors({});
        setStatus('Message sent successfully!');
      } else {
        setStatus('Failed to send message.');
      }
    } catch (error) {
      setStatus('Failed to send message.');
    } finally {
      setIsSubmitting(false); // Reset submitting state
    }
  };
  
  const validateForm = (data) => {
    const errors = {};
    if (!/^[A-Za-z\s]+$/.test(data.firstName.trim())) errors.firstName = 'Enter valid first name';
    if (!/^[A-Za-z\s]+$/.test(data.lastName.trim())) errors.lastName = 'Enter valid last name';
    if (!/^\d{10}$/.test(data.contactNo)) errors.contactNo = 'Mobile number must be 10 digits';
  
    return errors;
  };
  

  return (
    <>
      <footer id="footer">
        <div className="inner">
          <section>
            <h2>Get in touch</h2>
            <form onSubmit={handleSubmit}>
              <div className="fields">
                <div className="field half">
                  <input type="text" name="firstName" value={formData.firstName} id="firstName" maxLength={25} placeholder="First Name" onChange={handleChange} required/>
                  {errors.firstName && <p className="error">{errors.firstName}</p>}
                </div>
                <div className="field half">
                  <input type="text" name="lastName" value={formData.lastName} id="lastName" maxLength={25} placeholder="Last Name" onChange={handleChange} required/>
                  {errors.lastName && <p className="error">{errors.lastName}</p>}
                </div>
                <div className="field half">
                  <input type="email" name="email" value={formData.email} id="email" placeholder="Email" onChange={handleChange} required/>
                  {errors.email && <p className="error">{errors.email}</p>}
                </div>
                <div className="field half">
                  <input type="text" name="contactNo" value={formData.contactNo} id="contactNo" placeholder="Mobile No" onChange={handleChange} required/>
                  {errors.contactNo && <p className="error">{errors.contactNo}</p>}
                </div>
                <div className="field">
                  <textarea name="message" id="message" value={formData.message} placeholder="Message" onChange={handleChange}></textarea>
                </div>
              </div>
              <ul className="actions">
                <li><input type="submit" value="Send" className="primary" disabled={isSubmitting}/></li>
              </ul>
            </form>
            {isSubmitting && <p>Sending...</p>}
            {!isSubmitting && status && <p>{status}</p>}
          </section>
          <section>
            <h2>Contact Us</h2>
            <div id="map"></div>
            <br />
            <h3>United Kingdom <img src="images/uk.png" alt="" style={{ width: '30px', height: '20px' }} /></h3>
            <p className="address">Annai Exports & imports, <br />Croydon Close,<br />Coventry, UK. <br /> 
            <span class="label">Email : </span>
            enquiry@annaiexports.com
            <br />
            </p>
            <hr />  
            <div id="map1"></div><br />
            <h3>India <img src="images/India.png" alt="" style={{ width: '30px', height: '20px' }} /></h3>
            <p className="address">Annai Exports & imports, <br />No.2 / 58 / B1, <br />Anna Nagar, Pettavaithalai, <br />Srirangam Taluk, <br />Trichy District, <br />Tamil Nadu, India. <br /> 
            <span class="label">Email : </span>
            enquiry@annaiexports.com
            <br />
            <span class="label">IE Code : </span>
            AFCPA3331B
            <br />
            <span class="label">fssai No : </span>
            12424998000400
            <br />
            <span class="label">GST No : </span>
            33AFCPA3331B1ZK
            </p>
          </section>
          <ul className="copyright">
            <li>&copy; 2024 - Annai Exports. All rights reserved.</li>
            <li>Design and Developed by <a href="https://netboard.in/" rel="noreferrer" target="_blank"><img src="images/netboard.png" alt="" /></a></li>
          </ul>
        </div>
			</footer>
    </>
  );
}

export default Footer;
